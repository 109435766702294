;(function($){

	$(function(){

		var fs = $('.flexslider');

		fs.find('ul').addClass('slides');

		fs.flexslider();



    	//$('.typsi').tipsy();


		$("a[rel=example_group]").fancybox({
			'transitionIn'		: 'none',
			'transitionOut'		: 'none',
			'titlePosition' 	: 'inside'

		});

		$('#back').hide();

		$('a.displayHome').bind('click', function(){
			$("#myalbums").css('display', 'block');
			$("#myphotos").css('display', 'none');
			$("#back").css('display', 'none');
			$("#tochange").html('Albums');
		});


		$("#albums a").bind('click', function(){
			myvar = $(this).attr('id');
		    var albumtitile = document.getElementById(myvar).getAttribute("title")
		    var datas  = "albumid=" + myvar;

		   $("#back").css('display', 'block');
		    $("#myalbums").css('display', 'none');
		    $("#tochange").html(albumtitile);
		    $("#shuffleloader").css('display', 'block');
		     $.ajax({
		      url: "/sites/guillermoshairstudio.com/themes/guillermos_omega/facebum/lib/process.php",
		      type: "POST",
		      data: datas,
		      success: function(html){
		        $("#shuffleloader").css('display', 'none');
		        $("#myphotos").css('display', 'block');
		        $("#myphotos").html(html);
		        //$('.typsi').tipsy();
		        $("a[rel=example_group]").fancybox({
						'transitionIn'		: 'none',
						'transitionOut'		: 'none',
						'titlePosition' 	: 'inside'

					});

		      },
		      error: function(XMLHttpRequest, textStatus, errorThrown){
		          alert("Error occured");
		      }
		    });
		});
	});


    /**
     * Simple mobile navigation menu
     */
    $(function() {

        /**
         * Off canvas Menu
         */
        var offCanvasMenuContainer = $("body>nav");

        /**
         * Instantiate the menu
         */
        offCanvasMenuContainer.mmenu({
            "extensions": [
                  "pagedim-black",
                  "theme-dark"
            ],
            'offCanvas': {
                pageSelector: "#page",
                position: "right"
            },
            navbar: {
                add: false
            } 
        });

        /**
         * Open the menu
         */
        $(".hamburger").on('click touch', function(){
            offCanvasMenuContainer.data( "mmenu" ).open();
        });
    });

})(jQuery);
;
